/**/
import React, { Component } from "react";
import css from "./Checkbox.module.css";
import cx from "classnames";
import checkIcon from "@/assets/blueCheck.svg";

class Checkbox extends Component {
  render() {
    const {
      checked,
      onClick,
      name,
      children,
      className,
      labelClassName,
      large,
      disabled,
    } = this.props;
    return (
      <div
        onClick={!disabled ? onClick : () => {}}
        className={cx(className, css.container)}
      >
        <input
          name={name}
          className={css.checkboxHidden}
          type="checkbox"
          defaultChecked={checked}
        />
        <div
          className={cx(
            css.checkbox,
            { [css.checkboxLarge]: large },
            { [css.checkedLarge]: large && checked },
            { [css.disabledLargeCheckbox]: large && disabled }
          )}
        >
          {checked && !disabled && (
            <img className={cx({ [css.largeCheck]: large })} src={checkIcon} />
          )}
        </div>
        <div
          className={cx(
            css.text,
            { [labelClassName]: !disabled },
            { [css.textLarge]: large },
            { [css.disabledLargeText]: (large || !large) && disabled }
          )}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default Checkbox;
